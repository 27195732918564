import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Success: React.FC<PageProps> = () => {
    return (
        <Layout>
            <SEO title="Success" />
            <section className="hero is-dark mt-6">
                <div className="hero-body">
                    <div className="container">
                        <p className="title">CM Thunder Gold 18U</p>
                    </div>
                </div>
            </section>
            <section className="section my-4">
                <div className="container">
                    <p className="title has-text-centered">Thank you for contacting us, we will be in touch shortly.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Success
